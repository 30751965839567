<template>
  <div class="navi_gation_box">

    <van-sticky v-if="tabData.length > 0">
      <van-tabs v-if="type === 'optimization'" v-model="active" color="#F7263C" title-active-color="#F7263C" @click="tabClick">
        <van-tab v-for="(item,index) in tabData" :key="index" :title="item.stateRemind" />
      </van-tabs>
      <div v-else class="must_buy_tab">
        <van-tabs v-model="active">
          <van-tab v-for="(item,index) in tabData" :key="index">
            <template #title>
              <div @click="btn(item)">
                <div class="must_statetime">{{ item.time }}</div>
                <div class="must_stateRemind">{{ item.stateRemind }}</div>
              </div>
            </template>
          </van-tab>
        </van-tabs>
      </div>
    </van-sticky>
    <!--list-->
    <skeleton v-if="dataList.length===0" type="dataList" />
    <div v-else>
      <div v-for="(item,index) in dataList" :key="index" class="second_kill_list" @click="btnDataList(item)">

        <div class="second_kill_img">
          <div v-if="item.markerUrl" class="markerUrlIconMall">
            <img :src="item.markerUrl">
          </div>
          <imgLoading :src="item.goodCover" />
          <!-- <img :src="item.goodCover" alt=""> -->
        </div>
        <div class="second_kill_r">
          <div class="h6">{{ item.goodName }}</div>
          <div class="second_kill_r_top">
            <div class="second_l">
              <div class="second_l_name">
                <span class="h4">¥{{ item.goodVipPrice }}</span>
                <span class="h5">{{ item.goodPrice }}</span>
              </div>
              <div class="h6">
                <img src="../../assets/images/feng-ye.png" alt="">
                成长值:{{ item.newShareScore.buy }}
              </div>

            </div>
            <div class="second_r">
              <div class="second_r_btn">
                <h4>马上抢</h4>
                <div class="container">
                  <div class="second_unb" :style="{width:item.discount +'%'}" />
                </div>
                <h6>{{ item.discount }}%</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import imgLoading from '@/component/imgLoading'
import skeleton from '@/component/skeleton/index'
import { Icon, Lazyload, Sticky, Tab, Tabs, Toast } from 'vant'
import Vue from 'vue'
import { FlashGoods, flashActivity } from '@/services/api'
import { fetchOptimizationKillMallList, fetchOptimizationKillTimeList } from '@/services/optimization'

Vue.use(Tab).use(Tabs).use(Toast).use(Lazyload).use(Icon).use(Sticky)
export default {
  name: 'SecondKill',
  components: {
    skeleton, imgLoading
  },
  data() {
    return {
      active: '',
      dataList: [],
      tabData: '',
      showLoading: false,
      type: null
    }
  },
  mounted() {
    const { type } = this.$route.query
    this.type = type
    this.gitFlashActivity()
  },
  methods: {

    btn: function(t) {
      console.log(t)
      this.dataList = []
      this.gitFlashGoods(t.state, t.activityId)
    },

    tabClick: function(t) {
      const { tabData } = this
      this.dataList = []
      this.gitFlashGoods(tabData[t].state, tabData[t].activityId)
    },
    changeJdTab: function(name, title) {

    },
    //  获取  list 列表
    gitFlashGoods: function(state, activityId) {
      this.showLoading = true
      const data = {
        state: state,
        activityId: activityId
      }
      const { type } = this
      const c = type === 'optimization' ? fetchOptimizationKillMallList : FlashGoods

      c(data).then(res => {
        if (Number(res.code) === 200) {
          this.dataList = res.data
          this.showLoading = false
        }
      })
    },

    //  获取  list 列表
    gitFlashActivity: function() {
      const { type } = this
      const c = type === 'optimization' ? fetchOptimizationKillTimeList : flashActivity

      c().then(res => {
        if (Number(res.code) === 200) {
          this.tabData = res.data.activity.map((i, j) => {
            // 悦淘优选相关
            if (type === 'optimization') {
              if (j === 0) {
                i.stateRemind = '今日热卖'
              }
              if (j === 1) {
                i.stateRemind = '明日预告'
              }
            }
            return i
          })
          this.gitFlashGoods(res.data.activity[0].state, res.data.activity[0].activityId)
        }
      })
    },
    btnDataList: function(t) {
      const obj = {
        product_type: 2,
        goodId: t.goodId,
        skuId: t.productSkuId,
        activityId: t.activityId
      }
      this.$store.dispatch('godetail', obj)
    }

  }
}
</script>

<style lang="less" scoped>
.navi_gation_box {
  .must_stateRemind {
    font-size: 10px;
  }

  .must_statetime {
    font-size: 14px;
    display: grid;
  }
  .must_buy_tab {
    /deep/ .van-tab--active {
      color: red;
    }

    /deep/ .van-tabs__line {
      background: initial;
    }

    /deep/ .van-tab--active .must_stateRemind {
      background: red;
      color: #fff;
      border-radius: 10px;
      padding: 0px 4px;
      box-sizing: border-box;
    }

    /deep/ .van-tab__text--ellipsis {
      height: 100%;
      margin-top: 12px;
    }
  }

  width: 100%;
  background: #f6f6f6;

  .second_kill_list {

    text-align: left;
    margin-left: 12px;
    margin-right: 12px;
    height: 120px;
    background: #fff;
    margin-top: 12px;
    border-radius: 6px;
    overflow: hidden;

    .second_kill_img {
      position: relative;
      width: 120px;
      height: 120px;
      float: left;
      overflow: hidden;
      border-radius: 6px;
      // img{
      //   width: 100%;
      // }
    }

    .second_kill_r {
      float: left;
      padding: 8px 10px;
      box-sizing: border-box;
      width: 220px;

      .h6 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 18px;
      }

      .second_kill_r_top {
        margin-top: 30px;

        .second_l {
          float: left;

          .second_l_name {
            overflow: hidden;

            .h4 {
              color: red;
              font-size: 16px;
            }

            .h5 {
              color: #999;
              font-size: 12px;
              margin-left: 6px;
              text-decoration: line-through;
            }
          }

          .h6 {
            font-size: 10px;
            background: #000;
            color: #fff;
            border-radius: 0 8px 8px 10px;
            transform: scale(.9);
            padding: 0 7px;
            box-sizing: border-box;

            img {
              width: 12px;
              vertical-align: -3px;
            }
          }
        }

        .second_r {
          float: right;

          .second_r_btn {
            width: 75px;
            height: 35px;
            background: red;
            border-radius: 4px;

            h4 {
              text-align: center;
              color: #fff;
              font-size: 10px;
              padding: 6px 0 1px 0;
            }

            .container {
              width: 45px;
              background-color: rgba(255, 255, 255, .5);
              margin-left: 4px;
              border-radius: 4px;
              margin-top: 6px;
              float: left;
              overflow: hidden;
            }

            .second_unb {
              width: 0%;
              height: 4px;
              background-color: rgba(255, 255, 255, 1);
            }

            h6 {
              float: left;
              font-size: 10px;
              color: #fff;
              margin-top: 2px;
              transform: scale(.8);
            }
          }
        }
      }

    }

  }

}

.markerUrlIconMall {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 98;

  img {
    width: auto;
    height: 40px;
  }
}

</style>
